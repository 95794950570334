<template>
  <div style="display: flex; flex-direction: column">
    <h1>Менеджеры</h1>
<!--    <v-btn-->
<!--      elevation="2"-->
<!--      style="align-self: flex-end; margin-bottom: 10px"-->
<!--      @click="createManager"-->
<!--      >Добавить</v-btn-->
<!--    >-->
    <v-card>
      <v-data-table
        :headers="headers"
        :items="managers"
        @click:row="modalClick"
        hide-default-footer
          item-key="phone"
      >
        <template v-slot:item.name="{ item }">
          {{ item.first_name + " " + item.last_name }}
        </template>
        <template v-slot:item.owner="{ item }">
          {{ item.owner[0]?item.owner[0].name:'не установлен' }}
        </template>
      </v-data-table>
    </v-card>
    <div>
      <ManagersModal data-app
        :visible="visible"
        :value="manager"
        :owners="owners"
        @close="visible = false"
      ></ManagersModal>
    </div>
  </div>
</template>

<script>
import ManagersModal from "../components/ManagersModal";
export default {
  components: { ManagersModal },
  data() {
    return {
      headers: [
        { text: "ФИО", value: "name" },
        { text: "E-mail", value: "mail" },
        { text: "Телефон", value: "phone" },
        { text: "Партнер", value: "owner" },
        { text: "", value: "data-table-expand" },
      ],
      managers: [],
      manager: {
        first_name: "",
        last_name: "",
        phone: "",
        mail: "",
        owner_id: "",
        role: ""
      },
      visible: false,
      owners: [],
    };
  },
  async mounted() {
    const { data } = await this.$http.get(
      "/managers/"
    );
    this.managers = data;

    const owners = await this.$http.get("/owner/");
    this.owners = owners.data;
  },
  methods: {
    async createManager() {
      this.visible = true;
    },
    async modalClick(row) {
      this.manager = row;
      this.visible = true;
    },
    findOwner(row) {
      console.log(row);
      const owner = this.owners.filter((obj) => {
        return obj._id === row.owner_id;
      });
      console.log("app", owner);
      return owner[0].name;
    },
    async removeManager(item) {
      console.log('item',item)
      this.$http.delete('/managers/id/'+item._id)
      await this.mounted()
    }
  },
};
</script>

<style scoped></style>
