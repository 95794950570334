<template>
  <div style="display: flex; flex-direction: column">
    <h1>Тарифы</h1>
    <v-btn elevation="2" style="align-self: flex-end; margin-bottom: 10px" @click="createTariff">Добавить</v-btn>
    <v-card>
    <v-data-table
      :headers="headers"
      :items="tariffs"
      show-expand
      single-expand
      item-key="name"
      @click:row="modalClick"
      @item-expanded="findModules"
      hide-default-footer
    >
      <template v-slot:expanded-item="{ item }">
        <td style="flex-direction: row">
          <ModulesTable :items="modules" />

        </td>
        <div style="display: flex; flex-direction: row; margin-top: 40px">
          <v-btn class="modules-button" @click="addModules(item)"
          >Добавить модули</v-btn
          >
          <v-btn @click="deleteTariff(item)">Удалить</v-btn>
          <!--            <v-btn class="modules-button">test</v-btn>-->
        </div>
      </template>

    </v-data-table>
    </v-card>
    <div> <TariffModal :visible="modal" @close="modal=false" > </TariffModal>
    <ModulesModal :visible="modulesModal" @close="modulesModal=false" :modules="modalModules" :tariff_id="tariff_id"></ModulesModal> </div>
  </div>
</template>

<script>
import ModulesTable from "../components/ModulesTable";
import TariffModal from "../components/TariffModal";
import ModulesModal from "../components/ModulesModal";

export default {
  components: {ModulesModal, TariffModal, ModulesTable },
  data() {
    return {
      headers: [
        { text: "Название", value: "name" },
        { text: "Тариф", value: "tariff" },
        { text: "", value: "data-table-expand" },
      ],
      tariffs: [],
      modules: [],
      modal:false,
      modulesModal: false,
      modalModules: [],
      tariff_id: '',
    };
  },
  async mounted() {
    const { data } = await this.$http.get(
      "/module/tariffs/"
    );
    this.tariffs = data;
  },
  methods: {
    async modalClick(row) {
      console.log(row);
    },
    async createTariff() {
this.modal=true;
    },
    async findModules(row) {
      console.log(row);
      console.log("expand");
      const { data } = await this.$http.get(
        "/module/tariffid/" + row.item._id
      );
      console.log("modules", data[0].modules);
      this.modules = data[0];
      return this.modules;
    },
    async addModules(row) {
      console.log("row", row);
      const {data} = await this.$http.get(
        "/module/app/id/" + row.app_id
      );
      this.tariff_id= row._id;
      this.modalModules = data;
      this.modulesModal=true;

    }, deleteTariff(item) {
      console.log(item)
      this.$http.delete("/module/tariff/id/"+item._id)
    }
  },
};
</script>

<style>
.modules-button {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  margin-right: 15px;
}
</style>
