<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" v-show="visible">
      <div class="modal" style="width: 40%">
        <header class="modal-header">
          <slot name="header"> Менеджер </slot>
          <button type="button" class="btn-close" @click="close">x</button>
        </header>

        <section class="modal-body">
          <slot name="body">
            <v-form>
              <v-text-field v-model="value.first_name" required label="Имя">
              </v-text-field>
              <v-text-field v-model="value.last_name" required label="Фамилия">
              </v-text-field>

              <v-text-field v-model="value.phone" required label="Телефон">
              </v-text-field>

              <v-text-field v-model="value.mail" required label="E-mail">
              </v-text-field>

              <v-select v-model="value.role" :items="roles" label="Роль" filled></v-select>

              <v-radio-group v-model="value.owner_id" label="Партнер">
                <v-radio
                  v-for="owner in owners"
                  :key="owner.name"
                  :label="owner.name"
                  :value="owner._id"
                  required
                >
                </v-radio>
              </v-radio-group>
            </v-form>
          </slot>
        </section>

        <footer class="modal-footer">
          <button
            style="width: 30%; align-self: center"
            type="button"
            class="btn-green"
            @click="sendManager"
          >
            Сохранить
          </button>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ManagersModal",
  props: {
    visible: Boolean,
    value: Object,
    owners: Array,
  },
  data() {
    return {
      roles:[ {text:'Админ',value:'admin'},
        {text:'Менеджер',value:'manager'}
      ]
    };
  },
  async mounted() {},
  methods: {
    close() {
      this.$emit("close");
    },
    async sendManager() {
      await this.$http.post("/managers/", this.value);
      this.visible = false;
    },
  },
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: #4aae9b;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #4aae9b;
  background: transparent;
}

.btn-green {
  color: white;
  background: #4aae9b;
  border: 1px solid #4aae9b;
  border-radius: 2px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>
