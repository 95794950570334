<template>
  <div style="display: flex; flex-direction: column">
    <h1>Список лицензий</h1>
    <v-btn
        v-if="userRole==='admin'"
        elevation="2"
        style="align-self: flex-end; margin-bottom: 10px"
        @click="createLicense"
    >Добавить</v-btn
    >

    <v-card >
<v-data-table
    hide-default-footer
    :headers="headers"
    :items="applists"
    @click:row="editLicense"
>

  <template v-slot:item.number="{ index }">
      {{ index+1 }}
  </template>

<template v-slot:item.client="{ item }">
  {{ item.client.name }}
</template>

  <template v-slot:item.app="{ item }">
    {{ item.app.name }}
  </template>

  <template v-slot:item.manager="{ item }">
    {{ item.manager.first_name+ ' '+ item.manager.last_name }}
  </template>

  <template v-slot:item.tariff="{ item }">
    {{ item.tariff.name }}
  </template>

  <template v-slot:item.shop_count="{ item }">
    {{item.tariff.tariff*item.shops}}
  </template>

  <template v-slot:item.shops="{ item }">
    {{item.shops}}
  </template>

  <template v-slot:item.begin_date="{ item }">
    {{ formatDate(item.begin_date)}}
  </template>

  <template v-slot:item.end_date="{ item }">
    {{ formatDate(item.end_date)}}
  </template>

</v-data-table>
    </v-card>
    <div data-app><LicenseModal :visible="visible" :license="license" @close="visible=false"></LicenseModal></div>
  </div>
</template>

<script>
import LicenseModal from "../components/LicenseModal";
// import mapGetters from "vuex";
export default {
  components: {LicenseModal},
  data() {
    return {
headers: [
  {text:'№',value:'number'},
  {text:'Приложение', value:'app'},
  {text:'Менеджер', value:'manager'},
  {text:'Клиент',value:'client'},
  {text:'Тариф', value:'tariff'},
  {text:'Дата начала подписки', value: 'begin_date'},
  {text:'Дата окончания подписки', value: 'end_date'},
  {text:'Копии', value:'shops'},
  {text:'Сумма', value:'shop_count'},
  {text:'Статус лицензии', value:'status'}
],
      applists: [],
      visible:false,
      license: {
  _id:'',
  client_id: '',
        app_id: '',
        manager_id: '',
      tariff_id:'',
      begin_date: '',
      end_date: '',
      status: '',},
    }
  }, async mounted() {
    const {data} = await this.$http.get('/appList/')
    this.applists= data;
  },
  methods: {
    createLicense() {
      this.license={
        _id:'',
        client_id: '',
        app_id: '',
        manager_id: '',
        tariff_id:'',
        begin_date: '',
        end_date: '',
        status: '',};
      this.visible=true;
    },
    editLicense(row) {
      this.license=row;
      this.visible=true;
    },
    formatDate(date) {
      if (!date) return '-'
      date = new Date((date));
      let dd = date.getDate();
      if (dd < 10) dd = '0' + dd;

      let mm = date.getMonth() + 1;
      if (mm < 10) mm = '0' + mm;

      let yy = date.getFullYear();
      if (yy < 10) yy = '0' + yy;

      return dd + '.' + mm + '.' + yy;
    }
  }, computed: {
    userRole: function () {return this.$store.getters.StateRole},
  },
}
</script>

<style scoped>

</style>