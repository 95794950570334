<template>
  <div>
    <h1> Создатели</h1>
    <div style="display: flex; flex-direction: column">
      <v-btn
        elevation="2"
        style="align-self: flex-end; margin-bottom: 10px"
        @click="addCreator"
        >Добавить</v-btn
      >
      <v-card>
        <v-data-table
          :headers="headers"
          :items="creators"
          @click:row="modalClick"
          hide-default-footer
          :single-expand="true"
          item-key="name"
        >
          <template v-slot:item.remove="{ item }">
            <a @click="removeCreator(item)" > Удалить</a>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <CreatorsModal :visible="modal" :value="value" @close="modal=false" :modalName="modalName"></CreatorsModal>
  </div>
</template>

<script>
import CreatorsModal from "../components/CreatorsModal";

export default {
  name:"Creators",
  components: {
    CreatorsModal
  },
  data() {
    return {
      headers: [
        { text: "Имя", value: "first_name" },
        { text: "Фамилия", value: "last_name" },
        {text: 'Удалить', value:'remove'}
      ],
      creators: [],
      modal: false,
      value: {
        first_name: "",
        last_name: "",
      },
      modalName: "",
    };
  },
  async mounted() {
    const { data } = await this.$http.get(
      "/apps/creators"
    );
    this.creators = data;
  },
  methods: {
    async modalClick(row) {
      this.modal = true;
      this.value = row;
      this.modalName = "Редактировать пользователя";
    },
    async addCreator() {
      this.modal = true;
      this.value = {
        first_name: "",
        last_name: "",
      };
      this.modalName = "Создать пользователя";
    },
    removeCreator(item) {
      this.$http.delete('/apps/creator/'+item._id)
    }
   },
};
</script>

<style scoped></style>
