<template>
  <div style="display: flex; flex-direction: column">
    <h1>Клиенты</h1>
    <v-btn
      elevation="2"
      style="align-self: flex-end; margin-bottom: 10px"
      @click="createClient"
      >Добавить</v-btn
    >
    <v-card>
      <v-data-table
        hide-default-footer
        :headers="headers"
        :items="clients"
        show-expand
        @click:row="modalClick"
        :single-expand="true"
        item-key="name"
      >
        <template v-slot:item.contact="{ item }">
          {{ item.person_first_name + ' ' + item.person_last_name}}
        </template>
        <template v-slot:expanded-item="{headers,item}">
          <td :colspan="headers.length">
            <v-btn @click="removeClient(item)">Удалить</v-btn>
          </td>
        </template>
      </v-data-table>
    </v-card>
    <div><ClientsModal :visible="visible" :value="client" @close=" visible = false"></ClientsModal></div>
  </div>
</template>

<script>
import ClientsModal from "../components/ClientsModal";
export default {
  components: {ClientsModal},
  data() {
    return {
      headers: [
        { text: "Название", value: "name" },
        { text: "Телефон", value: "phone" },
        { text: "E-mail", value: "mail" },
        { text: "Контактное лицо", value: "contact" },
        { text: "", value: "data-table-expand" },

      ],
      clients: [],
      visible: false,
      client: {
        name: "",
        phone: "",
        mail: "",
        inn: "",
        kpp: "",
        person_first_name: "",
        person_last_name: "",
      },
    };
  },
  async mounted() {
    const {data} = await this.$http.get('/clients/')
    this.clients=data;
  },
  methods: {
    createClient() {
      this.visible=true;
    },
    modalClick(item) {
      this.client=item;
      this.visible=true;
    },
    async removeClient(item) {
      this.$http.delete('/clients/id/' + item._id);
      await this.mounted()
    }
  },
};
</script>

<style scoped></style>
