var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[_c('h1',[_vm._v("Список лицензий")]),(_vm.userRole==='admin')?_c('v-btn',{staticStyle:{"align-self":"flex-end","margin-bottom":"10px"},attrs:{"elevation":"2"},on:{"click":_vm.createLicense}},[_vm._v("Добавить")]):_vm._e(),_c('v-card',[_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.applists},on:{"click:row":_vm.editLicense},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index+1)+" ")]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.client.name)+" ")]}},{key:"item.app",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.app.name)+" ")]}},{key:"item.manager",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.manager.first_name+ ' '+ item.manager.last_name)+" ")]}},{key:"item.tariff",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.tariff.name)+" ")]}},{key:"item.shop_count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.tariff.tariff*item.shops)+" ")]}},{key:"item.shops",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.shops)+" ")]}},{key:"item.begin_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.begin_date))+" ")]}},{key:"item.end_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.end_date))+" ")]}}])})],1),_c('div',{attrs:{"data-app":""}},[_c('LicenseModal',{attrs:{"visible":_vm.visible,"license":_vm.license},on:{"close":function($event){_vm.visible=false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }