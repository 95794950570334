<!-- Партнеры -->
<template>
  <div style="display: flex; flex-direction: column">
    <h1>Партнеры</h1>
    <v-btn elevation="2" style="align-self: flex-end; margin-bottom: 15px" @click="createOwner">Добавить</v-btn>
    <v-card>
<v-data-table :headers="headers"
              show-expand
              :items="owners"
              @item-expanded="findManagers"
              @click:row="rowClicked"
hide-default-footer>
  <template v-slot:expanded-item="{ headers, item }">
<td :colspan="headers.length">
  <div style="display: flex; flex-direction: row; margin-bottom: 15px; height:55%; margin-top: 10px">
 <v-card width="49%" style="margin-right: 30px"> ИНН: {{ item. inn }}

 </v-card>
  <v-card width="49%" >  КПП: {{item.kpp}} </v-card>
  </div>
  <v-card style="margin-bottom: 10px" elevation="10"><strong>Менеджеры</strong>
  <ManagersTable :managers="managers"></ManagersTable></v-card>
  <v-btn style="margin-bottom: 10px" @click="deleteOwner(item)">Удалить</v-btn>
</td>
  </template>
</v-data-table>
    </v-card>
    <div><OwnerModal :visible="visible" :value="value" @close="visible=false;"></OwnerModal> </div>
  </div>
</template>

<script>
import OwnerModal from "../components/OwnerModal";
import ManagersTable from "../components/ManagersTable";
export default {
  components: {ManagersTable, OwnerModal},
  data() {
    return {
headers:[
  {text:"Наименование", value: "name"},
  {text:"Телефон", value:"phone"},
  {text:"", value:"data-table-expand"},
],
      owners: [],
      visible:false,
      value: {
  name:'',
        phone: '',
        inn: '',
        kpp: ''
      },
      managers: [],
    }
  }, async mounted() {
    const {data} = await this.$http.get('/owner/')
    this.owners=data;
  }, methods: {
    createOwner() {
this.visible=true;
    },
    rowClicked(row) {
      this.visible=true;
      this.value=row;
    },
    deleteOwner(row) {
      this.$http.delete('/owner/id/'+row._id)
    },
    async findManagers(item) {
      const {data} =await this.$http.get('/managers/ownerid/'+item.item._id)
  this.managers = data;
    }
  },
}
</script>

<style scoped>

</style>