<template>
  <div style="display: flex; flex-direction: column">
    <h1>Модули</h1>
    <v-btn elevation="2" style="align-self: flex-end; margin-bottom: 10px" @click="createModule">Добавить</v-btn>
<v-data-table :headers="headers" :items="modules"
              hide-default-footer>
<template v-slot:item.app="{item}">
  {{item.app.name}}
</template>
  <template v-slot:item.remove="{item}">
    <v-btn @click="deleteModule(item)">Удалить</v-btn>
  </template>
</v-data-table>
    <div><AddModule :visible="modal" @close="modal=false;"></AddModule></div>
  </div>
</template>

<script>
import AddModule from "../components/AddModule";
export default {
  components: {AddModule},
  data() {
    return {
      headers: [
        {text: "Название модуля", value: "name"},
        {text: "Приложение", value: "app"},
        {text: "Удалить", value:"remove"},
      ],
      modules: [],
      apps: [],
      modal:false
    }
  }, async mounted() {
    const {data} = await this.$http.get("/module/")
    this.modules=data;
    const app = await this.$http.get("/apps/")
    this.apps=app.data;
  }, methods: {
     findApp(row) {
      console.log(row)
      const app = this.apps.filter(obj => {
        return obj._id === row.app_id
      })
      console.log('app',app)
      return app[0].name
    },
    createModule() {
       this.modal=true;
    },
    deleteModule(item) {
      this.$http.delete("/module/id/"+item._id)
    }
  },
}
</script>

<style scoped>

</style>