<template>
  <div>
<v-data-table :headers="headers" :items="managers" hide-default-footer>
<template v-slot:item.name="{ item} ">
  {{ item.first_name + ' ' + item.last_name}}
</template>
</v-data-table>
  </div>
</template>

<script>
export default {
  name:'ManagersTable',
  props: {
    managers: Array,
  },
  data() {
    return {
      headers: [
        { text:'ФИО', value: 'name'},
        {text:'Телефон', value: 'phone' },
        {text:'E-mail', value: 'mail'},
      ]
    }
  },
}
</script>

<style scoped>

</style>