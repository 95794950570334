<template>
  <div style="display: flex; flex-direction: row; width: 100%">
<v-data-table
    class="modules-table"
:headers="headers"
:items="items.modules"
hide-default-footer
>
  <template v-slot:item.remove="{ item }">
    <v-btn @click="removeModule(item)" small>Удалить модуль</v-btn>
  </template>
</v-data-table>
  </div>
</template>

<script>
export default {
  name:"ModulesTable",
  props: {
    items:Array,
  },
  data() {
    return {
      headers:[
        {text:"Название модуля",value:"name"},
        {text:" ",value:"remove", sortable:false},
      ]
    }
  }, methods: {
async removeModule(item) {
  await this.$http.delete('/module/tariff/id/?module_id='+item._id+'&tariff_id='+this.items._id)
}
  },
}
</script>

<style scoped>
.modules-table {
  margin-bottom: 15px;
  margin-top: 5px;
  width: 60%;
  border:1px solid black;
}
</style>