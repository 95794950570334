<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" v-show="visible">
      <div class="modal" style="width: 50%">
        <header class="modal-header">
          <slot name="header">
            Лицензия
          </slot>
          <button type="button" class="btn-close" @click="close">x</button>
        </header>
<!---->
        <section class="modal-body">
          <slot name="body">
            <v-form>
              <div style="display: flex; flex-direction: row; margin-top: 10px">
                <div style="width: 50%; scale:0.85;">
              Клиент
              <v-autocomplete filled v-model="license.client_id" :items="clients" :disabled="userRole==='manager'">
              </v-autocomplete>
                </div>
                <div style="width: 50%; scale:0.85;">
              Приложение
              <v-autocomplete filled v-model="license.app_id" :items="apps" @change="findTariffs" :disabled="userRole==='manager'">
              </v-autocomplete>
                </div>
              </div>
              <div style="display: flex; flex-direction: row; margin-top: 10px">
                <div style="width: 50%; scale:0.85;">
              Менеджер
              <v-autocomplete filled v-model="license.manager_id" :items="managers" :disabled="userRole==='manager'">
              </v-autocomplete>
                </div>
                  <div style="width: 50%; scale:0.85;">
              Тариф
              <v-autocomplete filled v-model="license.tariff_id" :items="tariffs" :disabled="userRole==='manager'">
              </v-autocomplete>
                  </div>
              </div>
<!--              <select v-model="license.app_id">-->
<!--                <option v-for="app in apps" v-bind:value="app._id" :key="app.name">-->
<!--                  {{app.name}}-->
<!--                </option>-->
<!--              </select>-->
<!--              <select v-model="license.manager_id">-->
<!--                <option v-for="manager in managers" v-bind:value="manager._id" :key="manager.first_name">-->
<!--                  {{manager.name}}-->
<!--                </option>-->
<!--              </select>-->
<!--              <select v-model="license.tariff_id">-->
<!--                <option v-for="tariff in tariffs" v-bind:value="tariff._id" :key="tariff.name">-->
<!--                  {{tariff.name}}-->
<!--                </option>-->
<!--              </select>-->
<div style="display: flex; flex-direction: row; margin-top: 10px">
  <div style="width: 50%; scale:0.85">
    Дата начала лицензии: <strong>{{ formatDate(license.begin_date) }}</strong>
              <v-date-picker dark v-model="license.begin_date" :picker-date="license.begin_date"></v-date-picker>
  </div>
  <div style="width: 50%; scale:0.85">
    Дата окончания лицензии: <strong>{{ formatDate(license.end_date) }}</strong>
              <v-date-picker dark v-model="license.end_date" :picker-date="license.end_date"></v-date-picker>
  </div>
</div>
              <v-text-field :disabled="userRole==='manager'"
                            v-model="license.shops"
                            required
                            label="Количество копий"
              />
              <v-text-field :disabled="userRole==='manager'"
                  v-model="license.status"
                  required
                  label="Статус"
              >
              </v-text-field>
            </v-form>
          </slot>
        </section>

        <footer class="modal-footer">
          <button
              style="width: 30%; align-self: center"
              type="button"
              class="btn-green"
              @click="sendLicense"
          >
            Сохранить
          </button>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name:'LicenseModal',
  props: {
    visible: Boolean,
    license: Object,
  },
  data() {
    return {
clients:[],
      apps:[],
      managers:[],
      tariffs: [],
    }
  }, async mounted() {
    let {data} = await this.$http.get('/clients/');
    this.clients= data.map(el => ({text: el.name, value:el._id, disabled:false}));
    let apps = await this.$http.get('/apps/')
    this.apps= apps.data.map(el => ({text: el.name,value:el._id}))
    let managers = await this.$http.get('/managers/')
    this.managers= managers.data.map(el => ({text: el.first_name+' '+el.last_name,value:el._id}))


    let tariffs = await this.$http.get('/module/tariffs/')
    this.tariffs = tariffs.data.map(el => ({text: el.name,value:el._id}))


  },
  methods: {
    close() {
      this.$emit("close");
      this.license= {
        _id:'',
        client_id: '',
        app_id: '',
        manager_id: '',
        tariff_id:'',
        begin_date: '',
        end_date: '',
        shops:0,
        status: '',};
    },
    async sendLicense() {
      await this.$http.post('/appList/',this.license)
      this.visible=false;
      this.license= {
        _id:'',
        client_id: '',
        app_id: '',
        manager_id: '',
        tariff_id:'',
        begin_date: '',
        end_date: '',
        shops:0,
        status: '',};
    },
    async findTariffs(item) {
      console.log('item', item)
      let tariffs = await this.$http.get('/module/tariff/id/' + item)
      console.log(tariffs)
      this.tariffs = tariffs.data.map(el => ({text: el.name, value: el._id}))
    },
    formatDate(date) {
      if (!date) return '-'
      date = new Date((date));
      let dd = date.getDate();
      if (dd < 10) dd = '0' + dd;

      let mm = date.getMonth() + 1;
      if (mm < 10) mm = '0' + mm;

      let yy = date.getFullYear();
      if (yy < 10) yy = '0' + yy;

      return dd + '.' + mm + '.' + yy;
    }
  }, computed: {
    userRole: function () {return this.$store.getters.StateRole},
  },
}
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: #4aae9b;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #4aae9b;
  background: transparent;
}

.btn-green {
  color: white;
  background: #4aae9b;
  border: 1px solid #4aae9b;
  border-radius: 2px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>